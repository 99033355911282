<template>
	<div class="text-center m-4" v-if="loading">
		<b-spinner variant="primary" label="Spinning"></b-spinner>
	</div>
	<div v-else-if="loadingError" class="d-flex vh-100 vw-100">
		<h4 style="margin:auto auto;">
			There was an error retrieving your task. Please try again later, or contact an administrator
		</h4>
	</div>
	<div v-else-if="notFound" class="d-flex vh-100 vw-100">
		<h4 style="margin:auto auto;">
			This page no longer exists. If this was unexpected, please check your link, and try again.
		</h4>
	</div>
	<div v-else>
		<b-modal id="confirmUnsubscribe" variant="light" dismissible class="w-50 mx-auto" title="Confirm Unsubscribe">
			<p>
				Are you sure you'd like to unsubscribe? This action can not be undone.
			</p>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-between">
					<button type="button" class="btn-outline-blue" @click="$bvModal.hide('confirmUnsubscribe')">
						Close
					</button>
					<button class="btn-blue" @click="unsubscribe()">
						Unsubscribe
					</button>
				</div>
			</template>
		</b-modal>
		<div class="content-container mt-5 mb-5 d-flex flex-column">
			<div class="client-header border-bottom" v-if="this.client != null">
				<img v-if="this.client.logo != null" width="auto" :src="this.client.logo" />
				<img v-else width="auto" src="../assets/img/mdme-logo.png" />
				<div class="client-metadata">
					<div>{{ client.name || `Client #${client.id}` }}</div>
					<div v-if="client.contact.phoneNumber">
						{{ client.contact.phoneNumber }}
					</div>
					<div v-if="client.contact.supportEmail">
						<a :href="'mailto:' + client.contact.supportEmail">{{ client.contact.supportEmail }}</a>
					</div>
					<div v-if="client.contact.website">
						<a :href="cleanClientContactURL()">{{ client.contact.website }}</a>
					</div>
					<div v-if="client.contactAddr">
						{{ client.contactAddr }}
					</div>
				</div>
			</div>
			<div class="rendered-widget-container">
				<div v-if="consumerUnsubscribed" class="text-center mt-4">
					<h4 style="margin:auto auto;">
						You have unsubscribed. If you wish to undo this, please contact an administrator.
					</h4>
				</div>
				<div v-else-if="timelineUnsubscribed" class="text-center mt-4">
					<h4 style="margin:auto auto;">
						This timeline has been abandoned. If you wish to reset it, please contact an administrator.
					</h4>
				</div>
				<div v-else-if="completed" class="text-center mt-4">
					<h4 style="margin:auto auto;">
						Your response has been received. You may close this browser window.
					</h4>
				</div>
				<div v-else-if="inactiveTask" class="text-center mt-4">
					<h4 style="margin:auto auto;">This page is not available.</h4>
				</div>
				<template v-else>
					<form ref="form" id="dataform" @submit.prevent="onSubmit">
						<template class="" v-if="taskWidgets != null">
							<div class="task-preview mt-4 mb-4" v-for="(w, i) in taskWidgets" :key="i">
								<div v-if="w.widget.data">
									<div v-if="w.widget.data.type == 'text'" class="widget-item">
										<div v-html="insertConsumerData(w.widget.data.content.html)" class="text-widget"></div>
									</div>
									<div class="widget-item" v-else-if="w.widget.data.type == 'questionnaire'">
										<div v-for="(c, index) in w.widget.data.content" :key="index" class="mx-auto">
											<div v-if="c.type == 'text'" class="row mb-4">
												<label class="">{{ c.label }}</label>
												<input
													type="text"
													:name="w.widget.data.name + '[' + c.label + ']'"
													class="form-control form-control-sm"
													:placeholder="c.placeholder"
													v-model="c.value"
												/>
											</div>
											<div v-if="c.type == 'checkbox'" class="row">
												<label class="mr-2">{{ c.label }}</label>
												<div class="form-group">
													<div v-for="option in c.options" :key="option.id" class="form-check form-check-inline">
														<input
															class="form-check-input"
															type="checkbox"
															:name="w.widget.data.name + '[' + c.label + '][]'"
															:value="option.value"
														/>
														<label class="form-check-label">{{ option.text }}</label>
													</div>
												</div>
											</div>
											<div v-if="c.type == 'dropdown'" class="row mb-1">
												<label class="">{{ c.label }}</label>
												<b-form-select
													size="sm"
													:name="w.widget.data.name + '[' + c.label + ']'"
													:options="c.options"
													:value="c.value"
													class=""
												></b-form-select>
											</div>
										</div>
									</div>

									<div class="widget-item" v-else-if="w.widget.data.type == 'video'">
										<vue-plyr>
											<div class="plyr__video-embed">
												<iframe :src="w.widget.data.content" allowfullscreen allowtransparency allow="autoplay"></iframe>
											</div>
										</vue-plyr>
									</div>

									<div class="widget-item" v-else-if="w.widget.data.type == 'download'">
										<div class="download-widget">
											<p>{{ w.widget.data.content.message }}</p>
											<a class="back-btn" @click="downloadFile(w.widget.data.content.url, w.widget.data.content.fileName)"
												><i class="fas fa-download"></i>{{ w.widget.data.content.fileName }}</a
											>
										</div>
									</div>

									<div class="widget-item" v-else-if="w.widget.data.type == 'photo'">
										<div class="photo-widget">
											<img
												:src="w.widget.data.content.url"
												:aria-alt="w.widget.data.content.text || 'Photo'"
												style="max-width:100%;"
											/>
										</div>
									</div>
									<div class="widget-item" v-else-if="w.widget.data.type == 'delay'">
										<p class="delay-widget">
											<i class="far fa-clock"></i> This task will be sent
											{{ w.widget.data.content.substring('one') ? 'in' : 'on' }}
											{{ w.widget.data.content }}.
										</p>
									</div>

									<div class="widget-item" v-else-if="w.widget.data.type == 'upload'">
										<label :for="'file-upload-' + w.widget.data.name">{{
											w.widget.data.content && w.widget.data.content.message ? w.widget.data.content.message : 'Upload File:'
										}}</label>
										<b-form-file
											:id="'file-upload-' + w.widget.data.name"
											:name="w.widget.data.name"
											size="sm"
											variant="light"
										></b-form-file>
									</div>

									<div class="widget-item" v-else-if="w.widget.data.type == 'calendar'">
										<div class="calendar-widget">
											<a class="invite-link mb-2" :href="w.widget.data.content.url || '#'" target="_blank"
												><i class="far fa-calendar-plus"></i> {{ w.widget.data.content.invite.title }}</a
											>
											{{ convertDate(w.widget.data.content.invite.date) }},
											{{ convertTime(w.widget.data.content.invite.time_start) || 'No start time specified' }}
											-
											{{
												convertDuration(w.widget.data.content.invite.duration, w.widget.data.content.invite.time_start) ||
													'No end time specified'
											}}
											@
											{{ convertDate(w.widget.data.content.invite.location) }}
											<!-- <label
                        class="invitees mb-2"
                        v-if="
                          Array.isArray(
                            w.widget.data.content.invite.invitees.length
                          ) && w.widget.data.content.invite.invitees.length > 0
                        "
                        ><strong class="mr-2">Invitees: </strong>
                        {{
                          w.widget.data.content.invite.invitees.join(", ")
                        }}</label
                      > -->
											<!-- <label
                        class="mb-2"
                        v-if="w.widget.data.content.invite.message"
                        >{{
                          insertConsumerData(
                            w.widget.data.content.invite.message
                          )
                        }}</label
                      > -->
										</div>
									</div>

									<div v-else class="widget-item">
										{{ insertConsumerData(w.widget.data.content) }}
										<hr />
									</div>
								</div>

								<div class="widget-item" v-if="!w.widget.data">
									{{ w.widget.title }}
									<p>This widget has not been edited</p>
								</div>
							</div>
						</template>
					</form>
					<div class="w-100 d-flex">
						<button
							v-if="!preview && hasActions"
							:disabled="submitting"
							type="submit"
							class="btn-blue mx-auto mb-4"
							@click.stop.prevent="onSubmit"
						>
							Submit
						</button>
					</div>
				</template>
				<template v-if="!notFound && !consumerUnsubscribed && !loadingError">
					<div class="w-100 d-flex">
						<a href="#" class="mx-auto" @click.stop.prevent="$bvModal.show('confirmUnsubscribe')">
							Unsubscribe
						</a>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import router from '../router';
import { API } from 'aws-amplify';
import moment from 'moment';
import VuePlayer from '../components/VuePlayer';
import { google } from 'calendar-link';
Vue.use(VuePlayer);

export default {
	data() {
		return {
			// completion states
			completed: false,
			hasActions: false,
			notFound: false,
			loadingError: false,
			inactiveTask: false,
			taskWidgets: null,
			consumerUnsubscribed: null,
			timelineUnsubscribed: null,
			client: {
				id: 0,
				name: null,
				logo: null,
				contact: null,
				contactAddr: ''
			},
			loading: true,
			submitting: false
		};
	},
	props: ['previewClientID', 'previewConsumerID', 'previewTimelineID', 'previewTaskUUID', 'preview', 'task', 'consumer'],
	async mounted() {
		this.loading = true;
		// load in data
		try {
			let { clientID, consumerID, timelineID, taskUUID } = await this.$attrs;
			let taskData = null;
			let error = null;

			if (!this.preview) {
				if (!clientID || !consumerID || !timelineID || !taskUUID) {
					// ignore everything, get kicked to login.
					this.notFound = true;
					return;
				}
				const apiName = 'consumers';
				const path = `/consumers/${clientID}/${consumerID}/${timelineID}/${taskUUID}`;
				const payload = {
					path: path,
					httpMethod: 'get',
					headers: {
						'Content-Type': 'application/json'
					}
				};
				[taskData, error] = await API.get(apiName, path, payload)
					.then(async (result) => {
						return [result, null];
					})
					.catch((err) => {
						return [false, err];
					});
			} else {
				taskData = {
					task: {
						...this.task,
						widget_meta: this.task.widgetMeta
					},
					client: {
						id: this.getClientInContext.client_id,
						name: this.getClientInContext.client_name,
						contact: this.getClientInContext.client_contact
					}
				};
			}

			if (error != null || !taskData) {
				// TODO: handle 404 vs 500, right now just redirect to login.
				if (error == null) {
					this.notFound = true;
				} else if (!error.response || !error.response.status) {
					this.loadingError = true;
				} else if (error.response.status == 404) {
					this.notFound = true;
				} else if (error.response.status == 406) {
					this.timelineUnsubscribed = true;
				} else if (error.response.status == 409) {
					this.completed = true;
				} else if (error.response.status == 410) {
					this.consumerUnsubscribed = true;
				} else if (error.response.status == 425 || error.response.status == 451) {
					this.inactiveTask = true;
				} else {
					this.loadingError = true;
				}
				if (this.loadingError || this.notFound) {
					return;
				}
			}
			//get client logo
			// set logo first
			if (error != null) {
				if (error.response && error.response.data && error.response.data.client) {
					this.client.id = error.response.data.client.id;
					this.client.name = error.response.data.client.name;
					this.client.contact = error.response.data.client.contact;
				}
			} else if (taskData) {
				this.client.id = taskData.client.id;
				this.client.name = taskData.client.name;
				this.client.contact = taskData.client.contact;
			}

			if (this.client.id != 0) {
				if (this.client.contact) {
					let addrParts = [];
					if (this.client.contact.address1) {
						addrParts.push(this.client.contact.address1);
					}
					if (this.client.contact.address2) {
						addrParts.push(this.client.contact.address2);
					}
					if (this.client.contact.address3) {
						addrParts.push(this.client.contact.address3);
					}
					if (this.client.contact.city) {
						addrParts.push(this.client.contact.city);
					}
					if (this.client.contact.state) {
						addrParts.push(this.client.contact.state);
					}
					if (this.client.contact.postalCode) {
						addrParts.push(this.client.contact.postalCode);
					}
					if (addrParts.length > 0) {
						this.client.contactAddr = addrParts.join(', ');
					}
				}

				let s3bucket = await this.$store.getters.getCurrentS3Bucket;
				let logoPath = `https://${s3bucket}.s3.amazonaws.com/${this.client.id}/logofile.png`;
				var self = this;
				await axios
					.get(logoPath)
					.then(() => {
						self.client.logo = logoPath;
					})
					.catch((e) => {
						// do nothing, say nothing.
					});
			} else {
				this.client = null;
			}

			if (taskData && taskData.task && taskData.task.widget_meta && typeof taskData.task.widget_meta == 'object') {
				// Logo set, inport task data
				this.taskWidgets = taskData.task.widget_meta;
				if (Array.isArray(this.taskWidgets) && this.taskWidgets.length > 0) {
					this.taskWidgets.forEach(async (taskWidget, i) => {
						if (taskWidget.widget.data) {
							let widgetDataType = taskWidget.widget.data.type.toLowerCase();
							if (widgetDataType == 'calendar') {
								let link = await this.generateICSFile(taskWidget.widget.data.content.invite);
								this.taskWidgets[i].widget.data.content.url = link;
							} else if (widgetDataType == 'questionnaire' || widgetDataType == 'upload') {
								this.hasActions = true;
							}
						}
					});
				}

				if (!this.preview && !this.hasActions && !taskData.task.completed) {
					this.onSubmit();
				}
			}
		} finally {
			this.loading = false;
		}
	},
	computed: {
		...mapGetters(['getClientInContext'])
	},
	methods: {
		async generateICSFile(invite) {
			let { title, date, time_start, duration, invitees, location, message } = invite;

			if (moment(date, true).isValid() && moment(time_start, true).isValid()) {
				let guest;

				const event = {
					title: title,
					description: message,
					start: time_start,
					location: location,
					guests: invitees
				};

				if (duration) {
					event.duration = [duration, 'm'];
				}

				const url = google(event);

				if (!url) {
					this.$store.dispatch('createErrors', 'Unable to create calendar link, please try again later.');
					return '#';
				}

				return url;
			}
			this.$store.dispatch('createErrors', 'Unable to create calendar link: unsupported date + time values');
			return '#';
		},
		convertDate(date) {
			if (date && moment(date, true).isValid()) {
				const strDate = moment(date).format('MMMM DD, YYYY');
				return strDate;
			}
			return date;
		},
		convertDuration(duration, time_start) {
			if (duration && time_start) {
				if (moment(time_start, 'HH:mm:ss', true).isValid() || moment(time_start, true).isValid()) {
					let strTime = moment
						.utc(time_start)
						.local()
						.add(duration, 'minutes')
						.format('h:mm A');
					return strTime;
				}
				return `${duration} minutes from ${time_start}`;
			}
		},
		convertTime(time) {
			if (time && (moment(time, 'HH:mm:ss', true) || moment(time, true).isValid())) {
				let strTime = moment
					.utc(time)
					.local()
					.format('h:mm A');
				return strTime;
			}
			return time;
		},
		async downloadFile(url, label) {
			axios
				.get(url, { responseType: 'blob' })
				.then((response) => {
					const blob = new Blob([response.data], { type: 'application/pdf' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = label;
					link.click();
					URL.revokeObjectURL(link.href);
					this.$bvToast.toast('Your file has been downloaded.', {
						title: 'Success',
						variant: 'info',
						autoHideDelay: 5000
					});
				})
				.catch((e) => {
					this.$bvToast.toast('There was an error downloading your file. Please ensure your file is an accepted file type.', {
						title: 'Error',
						variant: 'danger',
						autoHideDelay: 5000
					});
				});
		},
		async unsubscribe() {
			this.$bvModal.hide('confirmUnsubscribe');

			if (this.preview) {
				this.consumerUnsubscribed = true;
				return;
			}

			let { clientID, consumerID, timelineID, taskUUID } = await this.$attrs;
			if (!clientID || !consumerID || !timelineID || !taskUUID) {
				// ignore everything, get kicked to login.
				router.push('/').catch(() => {});
				return;
			}
			const apiName = 'consumers';
			const payload = {
				path: `/consumers/${clientID}/${consumerID}/${timelineID}/${taskUUID}/unsubscribe`,
				httpMethod: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				// TODO: remove input meta fix
				body: {}
			};

			let [taskData, error] = await API.post(apiName, payload.path, payload)
				.then(async (result) => {
					return [result, null];
				})
				.catch((err) => {
					return [false, err];
				});

			if (error != null || !taskData) {
				// TODO: handle 404 vs 500, right now just redirect to login.
				alert('Failed to unsubscribe, please try again.');
			} else {
				this.consumerUnsubscribed = true;
				return;
			}
		},
		async onSubmit() {
			if (this.submitting == (this.submitting = true)) {
				return false;
			}
			let { clientID, consumerID, timelineID, taskUUID } = await this.$attrs;

			if (this.preview) {
				clientID = this.previewClientID;
				consumerID = this.previewConsumerID;
				timelineID = this.previewTimelineID;
				taskUUID = this.previewTaskUUID;
			}

			if (!clientID || !consumerID || !timelineID || !taskUUID) {
				// ignore everything, get kicked to login.
				this.$router.push({ name: 'Login' }).catch(() => {});
				return;
			}
			let inputMeta = {};
			let errors = [];
			let currentFormData = new FormData(this.$refs['form']);
			let entryIterator = currentFormData.entries();
			let next = entryIterator.next();
			while (next && next.done === false) {
				let [key, value] = next.value;
				if (value && typeof value == 'object' && value.constructor.name == 'File') {
					// only 1 file per name, NO ARRAY
					if (value.size && value.size > 0) {
						// sized file found
						let bytes = new Uint8Array(await value.arrayBuffer());
						// each base64 value is 6 bits, so it will round up 6 additional bits. basically we need to check an "inflated" file size
						if (bytes.length > 5000000) {
							errors.push(`File ${key} is too large, please make sure your file is 5 MB or less`);
							next = entryIterator.next();
							continue;
						}
						let binary = '';
						for (let i = 0; i < bytes.byteLength; i++) {
							binary += String.fromCharCode(bytes[i]);
						}

						// type = filetype, encoded base64
						inputMeta[key] = {
							type: value.type,
							payload: btoa(binary)
						};
					}
				} else {
					// Reflect.has in favor of: object.hasOwnProperty(key)
					if (!Reflect.has(inputMeta, key)) {
						inputMeta[key] = value;
					} else {
						// onvert value to array type.
						if (!Array.isArray(inputMeta[key])) {
							inputMeta[key] = [inputMeta[key]];
						}
						inputMeta[key].push(value);
					}
				}
				next = entryIterator.next();
			}

			if (errors.length > 0) {
				this.$store.dispatch('createErrors', errors);
				this.submitting = false;
				return false;
			}

			const apiName = 'consumers';
			const payload = {
				path: `/consumers/${clientID}/${consumerID}/${timelineID}/${taskUUID}`,
				httpMethod: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: {
					inputMeta: inputMeta,
					completed: true
				}
			};

			try {
				this.loading = this.hasActions;
				let [response, error] = await API.post(apiName, payload.path, payload)
					.then(async (result) => {
						return [result, null];
					})
					.catch((err) => {
						return [false, err];
					});

				if (error != null) {
					if (error.response && error.response.code && error.response.code == 413) {
						alert('Error consuming your input. Please make sure all files being uploaded do not exceed 5MB');
					} else {
						alert('There was an error consuming your input, please try again');
					}
					this.loading = false;
					this.submitting = false;
				} else {
					// only give people the feedback if the really need it
					this.completed = this.hasActions;
					this.loading = false;
				}
			} finally {
				this.submitting = false;
			}
		},
		cleanClientContactURL() {
			try {
				// confirm url
				new URL(this.client.contact.website);
				return this.client.contact.website;
			} catch {
				if (this.client.contact.website.includes('//')) {
					// invalid uri
					return 'javascript:void()';
				}
				// suppose the protocol from elsewhere
				return `//${this.client.contact.website}`;
			}
		},
		insertConsumerData(text) {
			if (this.consumer) {
				const toReplace = text.match(/({{ .+? }})/gm);

				if (!toReplace) {
					return text;
				}

				let newText = text;

				toReplace.forEach((match) => {
					const key = match.replace(/({{ consumer\.)|( }})/gm, '');

					try {
						const value = this.consumer.meta[key];

						if (value) {
							newText = newText.replace(match, value);
						} else {
							newText = newText.replace(match, `&lt;${key}&gt;`);
						}
					} catch (err) {
						newText = newText.replace(match, `&lt;${key}&gt;`);
					}
				});

				return newText;
			}

			return text;
		}
	}
};
</script>
<style>
@import '../assets/css/main.css';
</style>
